import React from "react"
import { graphql } from "gatsby"
import { getCurrentHomepage } from "@bufox/gatsby-utils/fe/wp"
import PropTypes from "prop-types"
import Layout from "components/layouts/base-layout/base-layout"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import MainContent from "components/main-content/main-content"
import styled from "styled-components"

const Page = props => {
  const {
    className,
    location,
    data: {
      singlePage: {
        content,
        dateGmt,
        excerpt,
        featuredImage,
        language,
        modifiedGmt,
        seo,
        title,
        translations,
      },
      homepages,
      rawMenus,
      searchUrls,
      wpSettings,
      commonThemeSettings,
    },
  } = props
  const {
    acfThemeSettings: {
      generalSettings,
      footer,
      search,
      socialNetworks,
      socialNetworksShare,
    },
  } = commonThemeSettings

  const homepage = getCurrentHomepage(homepages, language.code)

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={homepage?.uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={excerpt}
          publishDate={dateGmt}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={title}
          translations={translations}
        />
        <MainContent
          breadcrumbs={{ breadcrumbs: seo.breadcrumbs }}
          className="post-content"
          content={content}
          image={featuredImage ? featuredImage.node : null}
          location={location}
          siteUrl={process.env.GATSBY_SITE_URL}
          socialNetworksShare={socialNetworksShare}
          title={title}
        ></MainContent>
      </div>
    </Layout>
  )
}

Page.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    homepages: PropTypes.object,
    rawMenus: PropTypes.object,
    singlePage: PropTypes.shape({
      content: PropTypes.string,
      dateGmt: PropTypes.string,
      excerpt: PropTypes.string,
      featuredImage: PropTypes.object,
      language: PropTypes.object,
      modifiedGmt: PropTypes.string,
      seo: PropTypes.object,
      slug: PropTypes.string,
      title: PropTypes.string,
      translations: PropTypes.array,
      uri: PropTypes.string,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
    themeSettings: PropTypes.object,
  }),
  location: PropTypes.object,
}

const StyledPage = styled(Page)``

export const pageQuery = graphql`
  query Page(
    $id: String
    $themeSettingsPageId: String
    $menusLocations: [WpMenuLocationEnum]
  ) {
    singlePage: wpPage(id: { eq: $id }) {
      ...PostInfo
    }
    ...CommonThemeSettings
    ...Homepages
    ...LocalizedMenus
    ...SearchUrls
    ...WpSettings
  }
`

export default StyledPage

export { Page }
